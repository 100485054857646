import React from 'react'
import Box from '@local/shared/components/System/Box'
import { CountdownEmailSupportTime } from 'src/components/Countdown'
import FastingProgrammeLeadCapture from 'src/components/Programme/FastingProgrammeLeadCapture'

const BottomofPage = ({ data }) => {
  return (
    <>
      <Box mt={'2em'}>
        <CountdownEmailSupportTime
          len={data.freeEmailSupportDays}
          countdown={data.freeEmailSupportDaysRemaining}
        />
      </Box>
      <FastingProgrammeLeadCapture name={data.name} email={data.email} />
    </>
  )
}

export default BottomofPage
