import React from 'react'
import Typography from '@local/shared/components/System/Typography'
import Box from '@local/shared/components/System/Box'
import Button from 'src/components/Button'
import { MdKeyboardArrowLeft } from 'react-icons/md'
import { Link } from '@reach/router'
import FastingSchedule from 'src/components/Programme/FastingSchedule'
import BottomofPage from 'src/components/Programme/BottomOfPage'

const FASTING_SCHEDULE = [
  [false, false, false, false],
  [false, false, true, false],
  [false, false, true, false],
  [false, false, true, false],
  [false, false, true, false],
  [false, true, true, false],
  [false, true, true, false],
]

const Phase5 = ({ id, data }) => {
  return (
    <>
      <Box maxWidth={800} mx={'auto'} pt={'2em'} px={'1em'}>
        <Typography variant={'h1'}>
          Your Personalised Fasting Protocol
        </Typography>
        <Box mt={'2em'}>
          <Typography variant={'h2'} mb={'0.25em'}>
            Phase 5
          </Typography>
          <Typography variant={'subtitle'} mt={'1em'}>
            Maintenance Mode
          </Typography>
          <Typography variant={'body1'} mt={'0.25em'}>
            After all this fasting, your cravings and habits will have well and
            truly changed. Now you just need to keep it that way.
          </Typography>
        </Box>
      </Box>
      <FastingSchedule
        title={'Recommended Fasting Schedule'}
        schedule={FASTING_SCHEDULE}
      />
      <Box maxWidth={800} mx={'auto'} mt={'1em'} px={'1em'}>
        <Typography variant={'body1'} mt={'0.5em'}>
          Adding in a 36h fast, once a week, is our favorite way to keep the
          fasting practice going. This can easily be extended into a 3 day fast,
          once a month, by repeating Phase 2.
        </Typography>
        <Typography variant={'body1'} mt={'0.5em'}>
          You can carry on in Phase 2 until you start seeing a plateau and then
          switch to Phase 3 to break through it, if you feel like that works
          better for you.
        </Typography>
        <Typography variant={'h3'} mt={'1em'}>
          Fasting as a Lifestyle
        </Typography>
        <Typography variant={'body1'} mt={'0.5em'}>
          Fasting cleanses the body and the mind. At any point in your life,
          whether for efficiency, if you feel yourself getting a bit too liberal
          with your diets, your cravings start to creep-up on you or you just
          feel like it, just drop in a 3-5 day fast. You're a pro now!
        </Typography>
        <Box
          maxWidth={800}
          display={'flex'}
          justifyContent={'space-between'}
          mt={'2em'}
        >
          <Link to={'../phase3'}>
            <Button
              size={'sm'}
              variant={'default'}
              leftIcon={MdKeyboardArrowLeft}
            >
              Phase 4
            </Button>
          </Link>
        </Box>
      </Box>
      <BottomofPage data={data} />
    </>
  )
}

export default Phase5
