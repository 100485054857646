import React from 'react'
import Typography from '@local/shared/components/System/Typography'
import Box from '@local/shared/components/System/Box'
import Button from 'src/components/Button'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import { Link } from '@reach/router'
import BottomofPage from 'src/components/Programme/BottomOfPage'

const Phase4 = ({ id, data }) => {
  return (
    <>
      <Box maxWidth={800} mx={'auto'} pt={'2em'} px={'1em'}>
        <Typography variant={'h1'}>
          Your Personalised Fasting Protocol
        </Typography>
        <Box mt={'2em'}>
          <Typography variant={'h2'} mb={'0.25em'}>
            Phase 4
          </Typography>
          <Typography variant={'subtitle'} mt={'1em'}>
            Cycle Phases 1, 2 and 3
          </Typography>
          <Typography variant={'body1'} mt={'0.25em'}>
            You decide how quickly you want to lose weight. Some people function
            better on longer fasts than others.
          </Typography>
        </Box>
      </Box>
      <Box maxWidth={800} mx={'auto'} mt={'1em'} px={'1em'}>
        <Typography variant={'h3'} mt={'1em'}>
          Use Your Gut
        </Typography>
        <Typography variant={'body1'} mt={'0.5em'}>
          We know. Phase 3 can be a little heavy the first time you do it. Feel
          free do go back to Phase 1 or Phase 2 after successfully completing
          Phase 3. Just know that the more consecutive weeks of Phase 3 you
          complete, the fewer weeks of weight-loss fasting you need to complete
          and the faster you can return to maintenance mode, Phase 5.
        </Typography>
        <Typography variant={'body1'} mt={'0.5em'}>
          You can carry on in Phase 2 until you start seeing a plateau and then
          switch to Phase 3 to break through it, if you feel like that works
          better for you.
        </Typography>
        <Typography variant={'h3'} mt={'1em'}>
          What to Move to Phase 5
        </Typography>
        <Typography variant={'body1'} mt={'0.5em'}>
          Phase 5 is maintenance mode. If you have gotten this far, then it may
          well be extremely beneficial for you to add fasting as part of your
          normal lifestyle to maintain a healthy weight without being too
          overzealous with the specifics of your day-to-day diet.
        </Typography>
        <Box
          maxWidth={800}
          display={'flex'}
          justifyContent={'space-between'}
          mt={'2em'}
        >
          <Link to={'../phase3'}>
            <Button
              size={'sm'}
              variant={'default'}
              leftIcon={MdKeyboardArrowLeft}
            >
              Phase 4
            </Button>
          </Link>
          <Link to={'../phase5'}>
            <Button
              size={'sm'}
              variant={'default'}
              rightIcon={MdKeyboardArrowRight}
            >
              Phase 5
            </Button>
          </Link>
        </Box>
      </Box>
      <BottomofPage data={data} />
    </>
  )
}

export default Phase4
