import React from 'react'
import LeadCapture from 'src/components/LeadCapture/LeadCapture'
import Typography from '@local/shared/components/System/Typography'
import Box from '@local/shared/components/System/Box'
import { MainCta2 } from 'src/components/Button'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import CalendlyButton from 'src/components/CalendlyButton'

const FastingProgrammeLeadCapture = React.forwardRef(({ name, email }, ref) => {
  const formRef = ref
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "savvas-profile.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Box mt={'2em'} borderTop={'solid 0.5em'} borderColor={'blue.light1'}>
      <LeadCapture
        title={
          <>
            Book your <span css={{ textDecoration: 'underline' }}>FREE</span>{' '}
            30-minute phone consultation!
          </>
        }
        bullets={
          <>
            <Typography variant={'h6'} as={'p'} mb={'0.5em'}>
              Do you need a hand with this weight-loss plan?
            </Typography>
            <Typography variant={'h6'} as={'p'}>
              During the call, we will:
            </Typography>
            <Box as={'ul'} css={{ paddingInlineStart: '24px' }}>
              <Typography variant={'h6'} mb={'0.5em'} as={'li'}>
                <strong>Evaluate your current methods</strong> and weight loss
                goals!
              </Typography>
              <Typography variant={'h6'} mb={'0.5em'} as={'li'}>
                Look at how we can make your current programme{' '}
                <strong>even more effective</strong>.
              </Typography>
              <Typography variant={'h6'} mb={'0.5em'} as={'li'}>
                Learn more secrets about{' '}
                <strong>how to keep hunger at bay!</strong>
              </Typography>
              <Typography variant={'h6'} mb={'0.5em'} as={'li'}>
                Learn how to lose weight{' '}
                <strong>without stepping foot in a gym!</strong>
              </Typography>
              <Typography variant={'h6'} mb={'0.5em'} as={'li'}>
                Learn how to <strong>finally stop the yo-yo dieting</strong> and
                look good forever!
              </Typography>
              <Typography variant={'h6'} mb={'0.5em'} as={'li'}>
                Tell you all about{' '}
                <strong>
                  the one single way to lose weight as fast as humanly possible!
                </strong>
              </Typography>
              <Typography variant={'h6'} mb={'0.5em'} as={'li'}>
                Help give you some accountability to{' '}
                <strong>make sure you hit your goals!</strong>
              </Typography>
            </Box>
          </>
        }
        rhs={
          <Box width={{ md: '100%' }}>
            <Box
              borderRadius={'50%'}
              css={{ overflow: 'hidden' }}
              border={'solid 0.25em'}
              borderColor={'blue.0'}
              mb={'1.25em'}
            >
              <Img
                fluid={data.file.childImageSharp.fluid}
                objectFit={'cover'}
              />
            </Box>
            <Typography variant={'h6'} as={'p'} textAlign={'center'}>
              Coach Savvas Nicholas
            </Typography>
          </Box>
        }
        formName={'lead-capture-consultation'}
        formAction={'/thank-you'}
        formFields={
          <>
            <CalendlyButton
              name={name}
              email={email}
              mx={'auto'}
              button={MainCta2}
            />
          </>
        }
      />
    </Box>
  )
})

export default FastingProgrammeLeadCapture
