import React, { useEffect, useRef } from 'react'
import Box from '@local/shared/components/System/Box'
import { TweenMax } from 'gsap/all'

const OFFSET_X = 20
const OFFSET_Y = 20

const Loading = props => {
  const flowerRef = useRef(null)
  const allRef = useRef(null)
  useEffect(() => {
    TweenMax.from(allRef.current, 0.25, {
      autoAlpha: 0,
      y: -20,
    })
    TweenMax.to(flowerRef.current, 10, {
      rotation: 360 * 5,
      transformOrigin: '50% 54%',
      yoyo: true,
      repeat: -1,
    })
  }, [])
  return (
    <Box
      as={'svg'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${100.93 + OFFSET_X} ${97.03 + OFFSET_Y}`}
      {...props}
    >
      <defs>
        <style>{`.cls-1{fill:#efe241;}.cls-2{fill:#222;}`}</style>
      </defs>
      <g transform={`matrix(1,0,0,1,${OFFSET_X / 2},${OFFSET_Y / 2})`}>
        <g ref={allRef}>
          <path
            ref={flowerRef}
            className="cls-1"
            d="M71.75,64.37q-1.77-2.43-3.61-4.6c2.23-.51,4.5-1.13,6.82-1.88,16.19-5.24,27.72-14.43,25.75-20.5S84,30.63,67.82,35.88q-2.85.93-5.49,2c.2-2.27.32-4.63.32-7.06C62.66,13.81,57.49,0,51.1,0S39.52,13.79,39.51,30.81c0,2,.08,3.95.21,5.84-2.09-.89-4.3-1.73-6.61-2.48C16.92,28.89,2.2,29.55.22,35.62S9.76,50.9,25.94,56.17c1.9.62,3.78,1.15,5.62,1.6-1.49,1.72-3,3.56-4.41,5.53-10,13.76-13.94,28-8.77,31.72s17.47-4.35,27.49-18.11q1.76-2.43,3.26-4.85C50.3,74,51.59,76,53,78c10,13.78,22.29,21.9,27.46,18.15S81.74,78.15,71.75,64.37Z"
          />
          <path
            className="cls-2"
            d="M50.19,39.53c9.91,0,15.17,5.42,15.17,12.7s-5.26,12.7-15.17,12.7-15-5.42-15-12.7S40.28,39.53,50.19,39.53Z"
          />
        </g>
      </g>
    </Box>
  )
}

export default Loading
