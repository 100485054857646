import React, { useEffect, useState } from 'react'
import { addDays, addWeeks, differenceInDays, startOfWeek } from 'date-fns'
import Box from '@local/shared/components/System/Box'
import Loading from 'src/components/Loading'
import Seo from 'src/components/Seo'
import { Typography } from '@local/shared/components/System'
import { useMixpanel } from 'gatsby-plugin-mixpanel'

const FREE_EMAIL_SUPPORT_DAYS = 14

const Programme = ({ id, children }) => {
  const userId = id
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const mixpanel = useMixpanel()
  useEffect(() => {
    fetch(`/.netlify/functions/server/${id}`)
      .catch(e => setError(e))
      .then(r => {
        if (r.ok) {
          return r.json()
        } else {
          setError(r.json())
          throw new Error('Something went wrong')
        }
      })
      // .then(res => mixpanel.identify(res.userId).then(() => res))
      .then(res => {
        const resParsed = {
          ...res,
          created: new Date(res.created),
          birthday: new Date(res.birthday),
        }
        const now = new Date()
        const startWeek = startOfWeek(resParsed.created, { weekStartsOn: 1 })
        const startOfNextWeek = addWeeks(startWeek, 1)
        const resModified = {
          ...resParsed,
          startDate: startOfNextWeek,
          freeEmailSupportDays: FREE_EMAIL_SUPPORT_DAYS,
          freeEmailSupportDaysRemaining: differenceInDays(
            addDays(resParsed.created, FREE_EMAIL_SUPPORT_DAYS),
            now
          ),
        }
        console.log('resModified', resModified)
        return resModified
      })
      .then(d => {
        setData(d)
        return d
      })
      .then(async d => {
        await mixpanel.identify(d.userId)
        await mixpanel.register({ $email: d.email })
      })
      .catch(e => console.log(e))
  }, [id, mixpanel, userId])
  return (
    <Box minHeight={'100vh'}>
      <Seo title={'Free Weight-Loss Programme'} />
      {!data && !error && (
        <Box width={'5em'} mx={'auto'} position={'relative'} top={'40vh'}>
          <Loading />
        </Box>
      )}
      {error && (
        <Box textAlign={'center'} mt={'2em'}>
          <Typography variant={'h1'}>#500</Typography>
          <Typography variant={'subtitle'}>An error has occurred.</Typography>
        </Box>
      )}
      {/*See: https://github.com/iamyojimbo/iat-rmt/blob/master/app/src/components/Layout.js#L106*/}
      {data &&
        React.cloneElement(children, {
          children: React.Children.map(children.props.children, child => {
            return React.cloneElement(child, { id, data })
          }),
        })}
    </Box>
  )
}

export default Programme
