import React from 'react'
import Typography from '@local/shared/components/System/Typography'
import Box from '@local/shared/components/System/Box'
import whyDietsFailImg from 'src/images/why-diets-fail.svg'
import Button from 'src/components/Button'
import { Link } from '@reach/router'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { CountdownEmailSupportTime } from 'src/components/Countdown'

const Intro = ({ data }) => {
  return (
    <Box maxWidth={800} mx={'auto'} py={'2em'} px={'1em'}>
      <Typography variant={'h1'} mb={'0.5em'}>
        Your Customised 5-Phase Weight-Loss Plan
      </Typography>
      <Typography variant={'subtitle'} mb={'1em'}>
        This plan spells out exactly how to lose 1 kg per week without feeling
        hungry or slowing down.
      </Typography>
      <Typography variant={'h2'} mt={'0.75em'} mb={'0.5em'}>
        How is the programme broken down?
      </Typography>
      <Typography variant={'body1'} mt={'0.25em'}>
        This programme is split into five parts. Each part shows you a basic
        eating schedule and a goal for you to decide when it's time to move on
        to the next phase.
      </Typography>
      <Typography variant={'h2'} mt={'0.75em'} mb={'0.5em'}>
        What is an eating schedule?
      </Typography>
      <Typography variant={'body1'} mt={'0.25em'}>
        An eating schedule uses a simple traffic-light system to tell you when
        to eat and when not to eat. This is a simple way of visualising
        time-restricted eating, which is a form of fasting.
      </Typography>
      <Typography variant={'h2'} mt={'0.75em'} mb={'0.5em'}>
        Is fasting the same as starvation?
      </Typography>
      <Typography variant={'body1'} mt={'0.25em'}>
        Absolutely not. Fasting is an ancient healing technique for the body and
        the mind and is a choice. Famine is enforced on you by your environment.
        We live in the age of the feast, where fasting has been systematically
        phased out by the forces of capitalism. Think: “You must have two eggs
        for breakfast!”, “Shreddies: Keeps hunger locked up 'till lunch” and so
        on.
      </Typography>
      <Typography variant={'h2'} mt={'0.75em'} mb={'0.5em'}>
        Why can't I just eat less rather than fast?
      </Typography>
      <Typography variant={'body1'} mt={'0.25em'}>
        The age-old-adage goes: “Calories in vs calories out”; if you eat, say,
        500 calories less per day, on average, then you will lose weight. The
        issue is that this is fundamentally flawed. The body fights its hardest
        to maintain something called homeostasis, i.e. the tendency to keep
        everything the same. If you have been overweight for more than just a
        few months or years, your body likes to keep it that way. That's just
        life. But clearly, that doesn't help you.
      </Typography>
      <Typography variant={'h2'} mt={'0.75em'} mb={'0.5em'}>
        So why have all my diets failed in the past?
      </Typography>
      <Typography variant={'body1'} mt={'0.25em'}>
        This is what happens. In the first few weeks of your diet, you might
        lose a bit of weight. However, unbeknownst to you, your body has started
        slowing its metabolism down. Meaning that, with the exact same food
        intake, what started as a 500 calorie deficit in week one of your diet,
        in week two is now only 400, and perhaps only 300 in week 3. As you get
        to week 4, it's now only 100, but now your body is trying to make you
        feel more hungry. You can no longer see weekly progress. You can't keep
        up the same regime of eating empty salads and saying “no” to seconds and
        so you fall off the waggon. Now you're not following your diet{' '}
        <strong>and</strong> your metabolism is now slower thanks to your diet,
        so guess what,{' '}
        <strong>you're going to put all the weight back on, and more!</strong>
      </Typography>
      <Box my={'1em'} bgcolor={'#fafafa'} borderRadius={10} p={'1em'}>
        <img src={whyDietsFailImg} alt={'Why diets fail'} />
      </Box>
      <Typography variant={'h2'} mt={'0.75em'} mb={'0.5em'}>
        What happens when we fast?
      </Typography>
      <Typography variant={'body1'} mt={'0.25em'}>
        You turn into a fat burning machine! The purpose of stored body-fat is
        as an energy-surplus, to be used for a rainy day. If you have a lot of
        it, it's just because that rainy day hasn't come yet. For our ancestors,
        this would have been a real possibility, but we live in the age of the
        feast. There is no famine.
      </Typography>
      <Typography variant={'body1'} mt={'0.25em'}>
        Fasting is by far the most effective way of reducing insulin. This is
        vital to allowing you to access your stored fat tissues for energy.
        Excess insulin levels are a primary marker of obesity and Type 2
        Diabetes and the cause of failing diets. While insulin is high, when in
        a calorie deficit, your body makes you hungry, searching for more
        glucose, rather than tapping into the hundreds of thousands of stored
        calories it already has.
      </Typography>
      <Typography variant={'body1'} mt={'0.25em'}>
        In addition, your body also up-regulates your metabolism and energy
        levels. The body has adapted to be at its most alert and efficient when
        searching for food. But while you have fat left on your body, it is not
        “starved”. You have 7,700 calories per kilo of body fat or energy to
        use. That's a lot of energy! You just need to train your body to use it.
      </Typography>
      <Typography variant={'h2'} mt={'0.75em'} mb={'0.5em'}>
        This is the easiest “diet” that ever existed!
      </Typography>
      <Typography variant={'body1'} mt={'0.25em'}>
        Firstly. It's completely free. Guaranteed. There's no better way to save
        money, look good and feel amazing at the same time. Secondly, its as
        simple as looking at the time to decide if you should be eating or not.
        While you should still be diligent with what you do end up eating during
        non-fasting periods, it generally doesn't matter if you are high-carb,
        Atkins, Keto, paleo, veggie, or a{' '}
        <OutboundLink
          href={'https://www.youtube.com/watch?v=N_Yaa_LMDcs'}
          target={'_blank'}
        >
          level 5 vegan
        </OutboundLink>
        .
      </Typography>
      <Typography variant={'body1'} mt={'0.25em'}>
        As with literally any “diet” in the world, make sure the calories that
        you do eat come primarily from whole, unprocessed foods. Typically, if
        it came out of a packet and doesn't look like the original, raw product,
        don't eat it. It's probably going to make you crave more and more.
      </Typography>
      <Typography variant={'h2'} mt={'0.75em'} mb={'0.5em'}>
        Wait a second... I get hungry if I haven't eaten for an hour!
      </Typography>
      <Typography variant={'body1'} mt={'0.25em'}>
        High insulin makes you hungry and, if you are overweight, it's likely
        that you have it in abundance. In the first few days of a fasting
        protocol, you will feel hungry. Day two is typically the hardest.
        However, once you ride out the initial wave of mild discomfort, there is
        a promised land of mental clarity and unbounded fat-loss waiting for
        you.
      </Typography>
      <Typography variant={'h2'} mt={'0.75em'} mb={'0.5em'}>
        What are simple strategies I can use to keep hunger at bay?
      </Typography>
      <Typography variant={'body1'} mt={'0.25em'}>
        Keep moving and walking around. This will enhance your fat-burning. In
        place of the time when you would be cooking or eating, just go for a
        gentle walk. It will make you forget about it.
      </Typography>
      <Typography variant={'body1'} mt={'0.25em'}>
        Secondly, you can prepare and drink bone-broth.{' '}
        <Link to={'/blog/a-way-to-lose-weight-quickly/#saturday-morning'}>
          You can see the recipe for this in a blog-post here.
        </Link>{' '}
        Make sure you drink water in abundance. It also helps with fat-burning.
        Caffeine helps so you can drink tea and coffee too, with a splash of
        whole-milk is fine.
      </Typography>
      <Typography variant={'body1'} mt={'0.25em'}>
        Once your insulin levels drop and your body adapts to its new
        eating-rhythm, hunger will be a thing of the past. Your tummy will feel
        light and any bloating will be gone. Just push through the mild,
        temporary discomfort and you will be rewarded.
      </Typography>
      <Typography variant={'h2'} mt={'0.75em'} mb={'0.5em'}>
        Who shouldn't fast?
      </Typography>
      <Typography variant={'body1'} mt={'0.25em'}>
        Absolutely <strong>do not fast</strong> if
      </Typography>
      <Typography as={'ul'} variant={'body1'} mt={'0.25em'}>
        <li>Underweight or malnourished</li>
        <li>Under the age of 18</li>
        <li>Pregnant or breastfeeding</li>
      </Typography>
      <Typography variant={'body1'} mt={'0.25em'}>
        Consult your doctor first if
      </Typography>
      <Typography as={'ul'} variant={'body1'} mt={'0.25em'}>
        <li>You have gout</li>
        <li>You are on medications</li>
        <li>You have diabetes Type 1 or Type 2</li>
        <li>You have gastroesophageal disease</li>
      </Typography>
      <Box mt={'2em'} mx={'auto'} textAlign={'center'}>
        <Link to={`../phase1`}>
          <Button>Ok, I'm ready to start!</Button>
        </Link>
      </Box>
      <Box mt={'2em'}>
        <CountdownEmailSupportTime
          len={data.freeEmailSupportDays}
          countdown={data.freeEmailSupportDaysRemaining}
        />
      </Box>
    </Box>
  )
}

export default Intro
