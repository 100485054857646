import React from 'react'
import { Router } from '@reach/router'
import Programme from 'src/components/Programme/Programme'
import Layout from 'src/layouts'
import PageLayout from 'src/components/PageLayout'
import Intro from 'src/components/Programme/Intro'
import Phase1 from 'src/components/Programme/Phase1'
import Phase2 from 'src/components/Programme/Phase2'
import Phase3 from 'src/components/Programme/Phase3'
import Phase4 from 'src/components/Programme/Phase4'
import Phase5 from 'src/components/Programme/Phase5'

const App = () => {
  return (
    <Layout>
      <PageLayout>
        <Router basepath={'/app/'}>
          <Programme path={'programme/:id'}>
            <Intro path={'intro'} />
            <Phase1 path={'phase1'} default />
            <Phase2 path={'phase2'} />
            <Phase3 path={'phase3'} />
            <Phase4 path={'phase4'} />
            <Phase5 path={'phase5'} />
          </Programme>
        </Router>
      </PageLayout>
    </Layout>
  )
}

export default App
