import React from 'react'
import Typography from '@local/shared/components/System/Typography'
import Box from '@local/shared/components/System/Box'
import Button from 'src/components/Button'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import { Link } from '@reach/router'
import FastingSchedule from 'src/components/Programme/FastingSchedule'
import { addWeeks } from 'date-fns'
import BottomofPage from 'src/components/Programme/BottomOfPage'

const FASTING_SCHEDULE = [
  [false, false, false, false],
  [false, false, false, false],
  [false, false, false, false],
  [false, false, false, false],
  [false, false, true, false],
  [false, true, true, false],
  [false, true, true, false],
]

const Phase3 = ({ id, data }) => {
  return (
    <>
      <Box maxWidth={800} mx={'auto'} pt={'2em'} px={'1em'}>
        <Typography variant={'h1'}>
          Your Personalised Fasting Protocol
        </Typography>
        <Box mt={'2em'}>
          <Typography variant={'h2'} mb={'0.25em'}>
            Phase 3
          </Typography>
          <Typography variant={'subtitle'} mt={'1em'}>
            5 Day Fast
          </Typography>
          <Typography variant={'body1'} mt={'0.25em'}>
            This week, you will fast from Sunday night until Friday evening.
            However, you can extend this if you like. Each consecutive
            fasting-day adds more total weight-loss.
          </Typography>
        </Box>
      </Box>
      <FastingSchedule
        startDate={addWeeks(data.startDate, 3)}
        durationDays={6}
        schedule={FASTING_SCHEDULE}
      />
      <Box maxWidth={800} mx={'auto'} mt={'1em'} px={'1em'}>
        <Typography variant={'h3'} mt={'1em'}>
          You're Now a Fat Burning Machine
        </Typography>
        <Typography variant={'body1'} mt={'0.5em'}>
          During this phase, you are really seeing the benefits of all your
          training in fasting. You should be able to survive so easily without
          feeling hungry. You'll be at maximum efficiency at work with no
          interruptions, no after-lunch lull, no upset bellies, no-bloating, no
          uncontrollable cravings. All while your metabolism is recovering from
          years of restrictive dieting while you get closer to the body you have
          always wanted, saying goodbye to the risks of cardiovascular disease,
          type 2 diabetes, reducing your risk of Alzheimers disease and cancer.
          There are just so many other benefits, it's impossible to list them
          here.
        </Typography>
        <Typography variant={'body1'} mt={'0.5em'}>
          You will now see 1 kg of lasting weight-loss at the absolute very
          least. This will take a few days to equalise back to your normal
          resting non-fasted weight, which will be around 50% higher than your
          lowest weight registered when fasting.
        </Typography>
        <Typography variant={'h3'} mt={'1em'}>
          Extending the Fast
        </Typography>
        <Typography variant={'body1'} mt={'0.5em'}>
          Technically, you can fast as long as you have body fat left. The
          longest clinically studied fast is 382 days! However, there are
          potential complications when re-feeding so we don't recommend fasting
          longer than 7 days without close monitoring by a doctor. At WildLife,
          we have done 10 day fasts with no monitoring with absolutely no
          problem, but we know ourselves well and are very experienced. Keep
          pushing your own boundaries but also know that you are still getting
          massive benefits by doing just 5 days, re-feeding, and then doing
          another 3 or 5 days.
        </Typography>
        <Box
          maxWidth={800}
          display={'flex'}
          justifyContent={'space-between'}
          mt={'2em'}
        >
          <Link to={'../phase2'}>
            <Button
              size={'sm'}
              variant={'default'}
              leftIcon={MdKeyboardArrowLeft}
            >
              Phase 2
            </Button>
          </Link>
          <Link to={'../phase4'}>
            <Button
              size={'sm'}
              variant={'default'}
              rightIcon={MdKeyboardArrowRight}
            >
              Phase 4
            </Button>
          </Link>
        </Box>
      </Box>
      <BottomofPage data={data} />
    </>
  )
}

export default Phase3
