import React from 'react'
import Typography from '@local/shared/components/System/Typography'
import Box from '@local/shared/components/System/Box'
import Button from 'src/components/Button'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import { Link } from '@reach/router'
import FastingSchedule from 'src/components/Programme/FastingSchedule'
import { addWeeks } from 'date-fns'
import BottomofPage from 'src/components/Programme/BottomOfPage'

const FASTING_SCHEDULE = [
  [false, false, false, false],
  [false, false, false, false],
  [false, false, true, false],
  [false, false, true, false],
  [false, false, true, false],
  [false, true, true, false],
  [false, true, true, false],
]

const Phase2 = ({ id, data }) => {
  return (
    <>
      <Box maxWidth={800} mx={'auto'} pt={'2em'} px={'1em'}>
        <Typography variant={'h1'}>
          Your Personalised Fasting Protocol
        </Typography>
        <Box mt={'2em'}>
          <Typography variant={'h2'} mb={'0.25em'}>
            Phase 2
          </Typography>
          <Typography variant={'subtitle'} mt={'1em'}>
            Weekly 69h Fast
          </Typography>
          <Typography variant={'body1'} mt={'0.25em'}>
            Each week, you will fast from Sunday night until Wednesday evening.
            This is designed to still allow you to enjoy social occasions on the
            weekend. You will fast intermittently on all other days, mostly
            extending the fast past breakfast. This gets you into a much deeper
            fat-burning mode by doing more consecutive fasting days.
          </Typography>
        </Box>
      </Box>
      <FastingSchedule
        startDate={addWeeks(data.startDate, 2)}
        durationDays={13}
        schedule={FASTING_SCHEDULE}
      />
      <Box maxWidth={800} mx={'auto'} mt={'1em'} px={'1em'}>
        <Typography variant={'h3'} mt={'1em'}>
          What to Expect
        </Typography>
        <Typography variant={'body1'} mt={'0.5em'}>
          Again, the target is to do this for 2 weeks. Your insulin will be its
          lowest level in years. You will be in ketosis, which gives you a
          heightened sense of mental clarity and your energy levels will be
          constant throughout the day because insulin is not spiking.
        </Typography>
        <Typography variant={'body1'} mt={'0.5em'}>
          You will now see at least 0.5 kg of lasting weight-loss per week. On
          the scales, this will equate to around 0.3 - 0.5 kg per day, but you
          should expect to add some of this back on during your eating windows.
          Naturally, you will consume around 20% more calories than normal, but
          this will be vastly outweighed by your fasting period. You could be
          down 1 kg by the time you eat, but some of that is water-weight which
          will add back on as soon as you eat any carbohydrates.
        </Typography>
        <Typography variant={'h3'} mt={'1em'}>
          Breaking your Fast
        </Typography>
        <Typography variant={'body1'} mt={'0.5em'}>
          When it's time to eat, food is going to taste better than you have
          ever imagined. You're going to get cravings for cuisine that you
          didn't even realise you liked so much. Enjoy it! You have deserved it.
          Make sure you sit down and eat a good meal. All the same rules apply
          as in Phase 1: Don't break your fasting-window by snacking. Your eyes
          will be bigger than your stomach and you'll be surprised how quickly
          you get full. With the money you're saving on food and drink, spend it
          on cooking yourself a nice steak from the butchers or whatever you
          like eating.{' '}
          <strong>Warning: Everything is going to taste incredible!</strong>
        </Typography>
        <Typography variant={'body1'} mt={'0.5em'}>
          All the same rules apply as in Phase 1: Don't break your
          fasting-window by snacking. Eat a proper meal. Prepare yourself a
          3-course meal or even better, share it with friends and family. Go out
          to eat, you've saved a ridiculous amount of time and money!
        </Typography>
        <Typography variant={'h3'} mt={'1em'}>
          Hunger
        </Typography>
        <Typography variant={'body1'} mt={'0.5em'}>
          Dealing with hunger is all about riding the wave. It can be subdued
          massively with
          <Link to={'/blog/a-way-to-lose-weight-quickly/#preparation'}>
            bone-broth
          </Link>
          , caffeine and walking. After a certain time, the main hunger hormone,
          ghrelin, will completely subside once your insulin levels drop low
          enough and your body get into ketosis.
        </Typography>
        <Typography variant={'h3'} mt={'1em'}>
          What is Ketosis?
        </Typography>
        <Typography variant={'body1'} mt={'0.5em'}>
          Ketosis is an amazing feeling. It is a metabolic state where your body
          is primarily using ketones for fuel. Ketones are created in the liver
          from your body-fat as a response to low glucose levels, in this case,
          due to fasting. You may have heard of a ketogenic or Atkins diet;
          these diets aim to promote ketone production nutritionally by keeping
          carbohydrate consumption below 50 g per day. That's very, very low.
        </Typography>
        <Typography variant={'body1'} mt={'0.5em'}>
          Your brain functions amazingly on ketones. Expect to feel increased
          mental clarity and reduced brain-fog. Ketosis improves brain function
          so much that it is now a recognised treatment for epilepsy.
        </Typography>
        <Typography variant={'body1'} mt={'0.5em'}>
          What limits people during these diets is the 24/7 restriction. If you
          eat more than 50g of carbs in any single single day, you'll be kicked
          out of ketosis. It can take a number of days to get back into
          nutritional ketosis but only 2 days with fasting. Als, with fasting,
          you can enjoy a normal diet and lifestyle during non-fasting periods.
        </Typography>
        <Typography variant={'body1'} mt={'0.5em'}>
          There are a number of ways to test if you are in ketosis including
          pee-sticks, blood and breath analysers. But, the WildLife way is by
          both feeling and breath-holds. When in ketosis, you should be able to
          hold your breath for at least 50% longer than normal. Try this:
        </Typography>
        <Typography variant={'body1'} mt={'0.5em'}>
          On a non-fasting day, in the morning, as soon as you wake up, hold
          your breath and time it. You might get around 1 minute. Now, when
          fasting, from the second day onwards, do the same breath-hold, first
          thing in the morning. When in ketosis, you will find that you can do
          1:30 minutes easy and maybe even more. Magic. You are in ketosis.
        </Typography>
        <Typography variant={'h3'} mt={'1em'}>
          The Word on The Sleep
        </Typography>
        <Typography variant={'body1'} mt={'0.5em'}>
          Believe it or not, hunger will probably not be your biggest issue
          during a fast. However, sleeping well take some practice. This is
          because fasting increases your adrenaline and cortisol levels and you
          will generally be feeling quite alert. However, there are easy,
          WildLife ways to inhibit this:
        </Typography>
        <ol>
          <Typography variant={'body1'} mt={'0.5em'} as={'li'}>
            Get as much sunlight as possible, early in the day.
          </Typography>
          <Typography variant={'body1'} mt={'0.5em'} as={'li'}>
            Move as much as possible during the day.
          </Typography>
          <Typography variant={'body1'} mt={'0.5em'} as={'li'}>
            Stop drinking caffeine (tea or coffee) by 1 pm (even if caffeine
            seems to have little effect on you).
          </Typography>
          <Typography variant={'body1'} mt={'0.5em'} as={'li'}>
            Don't drink fluids too close to bedtime as this will cause you to
            need to pee in the middle of the night!
          </Typography>
          <Typography variant={'body1'} mt={'0.5em'} as={'li'}>
            Wind-down properly in the evening, limiting TV, phones and other
            stimuli. Read a book!
          </Typography>
          <Typography variant={'body1'} mt={'0.5em'} as={'li'}>
            Have a nice hot bath or shower.
          </Typography>
          <Typography variant={'body1'} mt={'0.5em'} as={'li'}>
            Sleep in a completely blacked-out room.
          </Typography>
          <Typography variant={'body1'} mt={'0.5em'} as={'li'}>
            As a final resort, you can have a few drops of CBD oil.
          </Typography>
        </ol>

        <Typography variant={'h3'} mt={'1em'}>
          Exercise
        </Typography>
        <Typography variant={'body1'} mt={'0.5em'}>
          As in Phase 1, and even more now, keep up the walking. Now you're
          really saving a lot of time: Walk everywhere possible. It's going to
          increase the efficacy of each fast so you will hit your target faster
          and that means less total weeks of fasting.
        </Typography>
        <Typography variant={'body1'} mt={'0.5em'}>
          You can still continue strength training as normal. Although you will
          see diminished strength after day 2, that doesn't mean that you're not
          adapting. Fasted training is still training for how to train with
          depleted resources. As with any training, the first time you try it,
          it will feel unusual and uncomfortable, but that comfort and
          familiarity with be improved with repetition, just like with fasting.
        </Typography>
        <Box
          maxWidth={800}
          display={'flex'}
          justifyContent={'space-between'}
          mt={'2em'}
        >
          <Link to={'../phase1'}>
            <Button
              size={'sm'}
              variant={'default'}
              leftIcon={MdKeyboardArrowLeft}
            >
              Phase 1
            </Button>
          </Link>
          <Link to={'../phase3'}>
            <Button
              size={'sm'}
              variant={'default'}
              rightIcon={MdKeyboardArrowRight}
            >
              Phase 3
            </Button>
          </Link>
        </Box>
      </Box>
      <BottomofPage data={data} />
    </>
  )
}

export default Phase2
