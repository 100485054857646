import React from 'react'
import Typography from '@local/shared/components/System/Typography'
import Box from '@local/shared/components/System/Box'
import Table from 'src/components/Table'
import { differenceInYears, format } from 'date-fns'
import { formatNumber } from 'src/format'
import Button from 'src/components/Button'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import { Link } from '@reach/router'
import FastingSchedule from 'src/components/Programme/FastingSchedule'
import BottomofPage from 'src/components/Programme/BottomOfPage'

const FASTING_SCHEDULE = [
  [false, false, true, false],
  [false, false, true, false],
  [false, false, true, false],
  [false, false, true, false],
  [false, false, true, false],
  [false, true, true, false],
  [false, true, true, false],
]

const DISTANCES_FROM_LONDON_KM = [
  { distance: 242, name: 'Cardiff' },
  { distance: 369, name: 'Amsterdam' },
  { distance: 393, name: 'Paris' },
  { distance: 593, name: 'Edinburgh' },
  { distance: 856, name: 'Bordeaux' },
  { distance: 1071, name: 'Berlin' },
  { distance: 1242, name: 'Milan' },
  { distance: 1515, name: 'Madrid' },
  { distance: 1766, name: 'Rome' },
  { distance: 1998, name: 'Lisbon' },
  { distance: 2369, name: 'Sofia' },
  { distance: 2764, name: 'Athens' },
  { distance: 2931, name: 'Istanbul' },
  { distance: 2931, name: 'Istanbul' },
  { distance: 3838, name: 'Cyprus' },
  { distance: 4883, name: 'Cairo' },
]

const findClosestCity = distance_km => {
  for (let k = 0; k < DISTANCES_FROM_LONDON_KM.length; k++) {
    const v = DISTANCES_FROM_LONDON_KM[k]
    if (distance_km < v.distance) {
      const index = k > 0 ? k - 1 : 0
      return DISTANCES_FROM_LONDON_KM[index].name
    }
  }
}

const SectionBox = ({ title, children }) => (
  <Box my={'2em'}>
    <Box
      display={'inline-block'}
      p={'1em'}
      pb={'0.5em'}
      bgcolor={'blue.light2'}
      borderRadius={10}
    >
      <Typography variant={'h2'}>{title}</Typography>
      {children}
    </Box>
  </Box>
)

const YourProfile = ({ data }) => {
  const rows = [
    { title: 'Name', value: data.name },
    {
      title: 'Age',
      value: differenceInYears(new Date(), data.birthday),
    },
    { title: 'Height', value: data.height_cm + ' cm' },
    { title: 'Starting Weight', value: data.startingWeight_kg + ' kg' },
    { title: 'Target Weight', value: data.targetWeight_kg + ' kg' },
    {
      title: 'Start Date',
      // Starts next monday
      value: format(data.startDate, 'EEEE, do MMMM'),
    },
  ]
  return (
    <SectionBox title={'Your Profile'}>
      <Table my={0}>
        <tbody>
          {rows.map((r, i) => (
            <tr key={i} css={{ verticalAlign: 'top' }}>
              <th css={{ textAlign: 'left' }}>{r.title}</th>
              <td css={{ textAlign: 'left' }}>{r.value}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </SectionBox>
  )
}

const YourTarget = ({ data }) => {
  const weightToLose_kg = data.startingWeight_kg - data.targetWeight_kg
  const cal_kg_bodyfat = 7700
  const storedEnergy_cal =
    (data.startingWeight_kg - data.targetWeight_kg) * cal_kg_bodyfat
  const walkingSpeed_km_h = 3
  const metWalking = 3.2 // @ 3 km/h
  const cal_min = (metWalking * 3.5 * data.startingWeight_kg) / 200
  const cal_h = cal_min * 60
  const cal_km = cal_h / walkingSpeed_km_h
  const distance_km = storedEnergy_cal / cal_km
  return (
    <SectionBox title={'Your Target'}>
      <Typography variant={'body1'} mt={'0.5em'}>
        You want to lose <strong>{formatNumber(weightToLose_kg)} kg</strong> of
        body fat.
      </Typography>
      <Typography variant={'body1'} mt={'0.5em'}>
        Each kilo of body fat is around {formatNumber(cal_kg_bodyfat)} calories
        of stored energy. That means that{' '}
        <strong>
          you have {formatNumber(storedEnergy_cal)} calories of stored body fat
        </strong>{' '}
        to spare.
      </Typography>
      <Typography variant={'body1'} mt={'0.5em'}>
        That's about{' '}
        <strong>
          enough energy to walk {formatNumber(Math.round(distance_km))} km
        </strong>{' '}
        without taking a single bite of food.
      </Typography>
      <Typography variant={'body1'} mt={'0.5em'}>
        That's about the distance between{' '}
        <strong>London and {findClosestCity(distance_km)}</strong>.
      </Typography>
    </SectionBox>
  )
}

const Phase1 = ({ id, data }) => {
  return (
    <>
      <Box maxWidth={800} mx={'auto'} pt={'2em'} px={'1em'}>
        <Typography variant={'h1'}>
          Your Personalised Fasting Protocol
        </Typography>
        <YourProfile data={data} />
        <YourTarget data={data} />
        <Box mt={'2em'} css={{ overflow: 'visible' }}>
          <Typography variant={'h2'} mb={'0.25em'}>
            Phase 1
          </Typography>
          <Typography variant={'subtitle'} mt={'1em'}>
            Intermittent Fasting
          </Typography>
          <Typography variant={'body1'} mt={'0.25em'}>
            Each day, restrict the time in which you eat. This should be kept
            constant each day so your body can adapt and help to control your
            hunger as it learns to anticipate calories.
          </Typography>
        </Box>
      </Box>
      <FastingSchedule
        schedule={FASTING_SCHEDULE}
        startDate={data.startDate}
        durationDays={13}
      />
      <Box maxWidth={800} mx={'auto'} mt={'1em'} px={'1em'}>
        <Typography variant={'h3'} mt={'1em'}>
          What to Expect
        </Typography>
        <Typography variant={'body1'} mt={'0.5em'}>
          The target is to do this for 2 weeks. Owed to this being the start of
          a new mode of eating, you should see around 0.5 kg of weight-loss each
          week. You might feel hungry for the first week leading up to
          dinner-time. This is training time for the longer fasting in later
          phases. Just keep drinking water, tea, coffee and{' '}
          <Link to={'/blog/a-way-to-lose-weight-quickly/#preparation'}>
            bone-broth
          </Link>
          .
        </Typography>
        <Typography variant={'body1'} mt={'0.5em'}>
          If you don't see 0.5 kg per week, don't fret. Your metabolism might
          just need a bit more of kick-start. The key part of this phase is to
          learn to deal with short periods without food.
        </Typography>
        <Typography variant={'h3'} mt={'1em'}>
          Breaking your Fast
        </Typography>
        <Typography variant={'body1'} mt={'0.5em'}>
          You will have a new-found appreciation for dinner-time. Make sure you
          sit down and eat a good meal. Don't break your fasting-window by
          snacking. Your eyes will be bigger than your stomach and you'll be
          surprised how quickly you get full. With the money you're saving on
          food and drink, spend it on cooking yourself a nice steak from the
          butchers or whatever you like eating.{' '}
          <strong>Warning: Everything is going to taste incredible!</strong>
        </Typography>
        <Typography variant={'h3'} mt={'1em'}>
          Hunger & Carbs
        </Typography>
        <Typography variant={'body1'} mt={'0.5em'}>
          Keeping the carbs to a minimum in this phase is the secret to staving
          off hunger, especially sugars and refined carbs. Eating carbs will
          kick you out of fat-burning mode by spiking insulin and high insulin
          makes you hungry. If you eat a high-carb meal in the evening, your
          insulin might not recover by the morning, and so you might feel
          hungry. If you are veggie and carbs are unavoidable, then avoid grains
          and stick to whole-foods such as legumes and sweet potatoes which have
          less impact on blood-insulin. If the truth be told, this is the only
          phase where what you do actually eat will impact you. However, once
          you get down towards your ideal weight and your insulin levels are
          naturally lower, eating carbs during your eating window is totally
          cool!
        </Typography>
        <Typography variant={'h3'} mt={'1em'}>
          Exercise
        </Typography>
        <Typography variant={'body1'} mt={'0.5em'}>
          Walking is another amazing way to lower insulin, keep you in
          fat-burning mode and, by doing so, keep hunger to minimum. Take the
          extra effort to go for a walk. If feeling drained of energy,
          kick-start your metabolism by going for a stroll. When you would
          normally be taking time out to eat, go for a walk. Get out the office
          and run some errands at lunchtime.
        </Typography>
        <Typography variant={'body1'} mt={'0.5em'}>
          Also, if you do any strength training, which is our favorite for
          weight-loss, don't go easy. You can train completely as normal. Your
          body will adapt and you will continue to see changes in your
          body-composition (fat vs muscle).
        </Typography>
        <Typography variant={'h3'} mt={'1em'}>
          Tracking Your Progress
        </Typography>
        <Typography variant={'body1'} mt={'0.5em'}>
          There are three primary ways to track progress, all of which are
          encouraged.
        </Typography>
        <Typography variant={'body1'} mt={'0.5em'}>
          Firstly,{' '}
          <strong>take a picture of yourself with your clothes off</strong> at
          the same time each week. Initially, you won't see any changes, but
          then, suddenly, it will become as clear as day. As you approach your
          ideal weight, you'll start to think to yourself “I can't believe I
          used to look like that!”.
        </Typography>
        <Typography variant={'body1'} mt={'0.5em'}>
          The secondary method is to track your weight with{' '}
          <strong>bathroom scales</strong>. You don't need to get fancy, just
          stand on them and write your weight down. You could use a fancier
          version that auto-logs it to an app so you can see a graph. Warning:
          Don't trust the other measurements that these scales can provide, such
          as body fat percentage. They are highly inaccurate. Just make sure you
          weigh yourself often enough to spot a trend. Weighing yourself just
          once a week isn't enough to generate a good average week-on-week. The
          great thing about fasting is that, as long as you have been compliant,
          you are going to lose weight. Weekly weigh-ins are just great to see
          if you have hit a plateau.
        </Typography>
        <Typography variant={'body1'} mt={'0.5em'}>
          The third, and probably the best, is to{' '}
          <strong>measure waist circumference.</strong> We prefer to do this as
          the widest point around the belly. This is the primary metric to
          reduce. Fasting is especially effective at reducing belly fat which is
          highly correlated to your insulin levels. Other diets might reduce
          your total body-weight but not reduce your chronically-high insulin
          levels. Track this at-least weekly on a sheet of paper of in an excel
          spreadsheet. You'll see it tumble over the next few weeks!
        </Typography>
        <Box
          maxWidth={800}
          display={'flex'}
          justifyContent={'space-between'}
          mt={'2em'}
        >
          <Link to={'../intro'}>
            <Button
              size={'sm'}
              variant={'default'}
              leftIcon={MdKeyboardArrowLeft}
            >
              Intro
            </Button>
          </Link>
          <Link to={'../phase2'}>
            <Button
              size={'sm'}
              variant={'default'}
              rightIcon={MdKeyboardArrowRight}
            >
              Phase 2
            </Button>
          </Link>
        </Box>
      </Box>
      <BottomofPage data={data} />
    </>
  )
}

export default Phase1
